import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.css'
import {FaRegUser} from "react-icons/fa";
import {IoIosArrowDown} from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '../../../actions/auth';

const Index = () => {

    const dispatch = useDispatch()
    const sortRef = useRef();
    const {displayname} = useSelector(state => state.auth)

    const [visiblePopup, setVisiblePopup] = useState(false)

    const toggleVisiblePopup = () => setVisiblePopup(!visiblePopup) //открытие компонента

    const handleOutsideClick = (event) => { //закрытие вне компонента
        const path = event.path || (event.composedPath && event.composedPath());
        if (!path.includes(sortRef.current)) {
            setVisiblePopup(false);
        }
    };

    useEffect(() => {
        document
            .body
            .addEventListener('click', handleOutsideClick);
    }, []);

    const logOut = () => dispatch(signOut()) //выход

    return (
        <header className={styles.header}>
            <div className={styles.info} ref={sortRef}>
                <div className={styles.user} onClick={toggleVisiblePopup}>
                    <i className={styles.avatar}><FaRegUser/></i>
                    <span className={styles.name}>{displayname}</span>
                    <i className={styles.arrow}><IoIosArrowDown/></i>
                    {visiblePopup && <ul className={styles.dropdown}>
                        <li>Профиль</li>
                        <li onClick={logOut}>Выйти</li>
                    </ul>
}
                </div>
            </div>
        </header>
    )
}

export default Index