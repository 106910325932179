import React from 'react'
import styles from './index.module.css'

const Index = ({
    text = 'Сохранить',
    type = 'primary',
    mr = '.5rem',
    ...props
}) => {

    const typeButton = styles[type]
    
    return (
        <button 
        {...props} 
        className={typeButton + ' ' + styles.button}
        style={{margin: mr}}
        >{text}</button>
    )
}

export default Index