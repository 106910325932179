import React from 'react'
import styles from './index.module.css'
import Empty from '../Empty'
import Loader from '../Loader'

const Index = ({isEmpty, isLoad, children}) => {
    return (
        <div className={styles.wrapper}>
            {!isLoad && <table className={styles.table} cellSpacing="0">{children}</table>}
            {isEmpty?.length === 0 && <Empty/>}
            {isLoad && <Loader/>}
        </div>
    )
}

export default Index