import React, {useEffect} from 'react'
import styles from './index.module.css'
import Modal from '../../../components/Modal'
import Button from '../../../components/Button'
import {useDispatch, useSelector} from 'react-redux'
import {getMessage} from '../../../actions/messages'
import moment from 'moment'
import {TbRefreshDot} from "react-icons/tb";

const Index = ({modal, setModal}) => {

    const dispatch = useDispatch()
    const {
        item = [],
        item_loading
    } = useSelector(state => state.messages)

    useEffect(() => {
        if (modal
            ?.conversation_id) 
            dispatch(getMessage(modal.conversation_id))
    }, [modal])

    const handleSubmit = () => {
        if (modal.conversation_id) {
            handleClose()
        }
    }

    const handleClose = () => {
        setModal(false)
        dispatch({type: 'SET_MESSAGE', payload: []})
    }

    const getTitle = () => {
        return <div className={styles.header}>
            Диалог
            <div className={styles.headerNumber}>#{modal
                    ?.conversation_id}</div>
            <div className={styles.headerDate}>
                {moment(modal
                    ?.created).format('DD.MM.YYYY HH:mm')}</div>
            <i
                title='Обновить'
                className={item && item.length && item_loading
                ? styles.icon + ' ' + styles.iconLoad
                : styles.icon}><TbRefreshDot onClick={() => dispatch(getMessage(modal.conversation_id))}/></i>
        </div>
    }

    return (
        <div className={styles.wrapper}>

            <Modal
                open={modal}
                singleTitle={getTitle()}
                width={1200}
                onClose={() => handleClose()}
                title='продукта'
                loading={item_loading}>

                <div className={styles.messagesContainer}>
                    <div className={styles.messagesWrapper}>
                        {item
                            ?.map((el, index) => (
                                <div key={index} className={styles.messages + ' ' + styles[el.role]}>
                                    <div className={styles.role}>
                                        <div className={styles.name}>{el.role}</div>
                                        <span>{moment(el.created).format('DD.MM.YYYY HH:mm')}</span>
                                    </div>
                                    <div className={styles.text}>{el.messages}</div>

                                </div>
                            ))}
                    </div>
                </div>

                <div className={styles.btns}>
                    <Button text='Закрыть' onClick={() => handleClose(false)} type='empty'/>
                    <Button text='Сохранить' onClick={handleSubmit}/>
                </div>
            </Modal>
        </div>
    )
}

export default Index