import React from 'react'
import styles from './TitleAndDesc.module.css'

const TitleAndDesc = ( {title, desc} ) => {
  return (
    <div className={styles.wrapper}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{desc}</p>
    </div>
  )
}

export default TitleAndDesc