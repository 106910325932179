import React from 'react'
import styles from './NotFound.module.css'
import { TbFaceIdError } from "react-icons/tb";

const NotFound = () => {
  return (
    <div className={styles.wrapper}>
        <p>Страница не найдена</p>
        <i><TbFaceIdError/></i>
        </div>
  )
}

export default NotFound