import React from 'react'
import styles from './styles.module.css'
import {useFormik} from 'formik'
import {Input} from './Components'
import {useDispatch, useSelector} from 'react-redux'
import {auth} from '../../actions/auth'
import {Navigate} from 'react-router-dom'
import {version} from '../../assets/const'

export default function Auth() {

    const dispatch = useDispatch()
    const {token} = useSelector(state => state.auth)

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur
    } = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        onSubmit: values => dispatch(auth(values))
    })

    if (token) {
        return <Navigate to='/'/>
    }

    return (
        <div className={styles.wrapper}>

            <header className={styles.header}>
                <h1>Furniture bot</h1>
            </header>
            <main>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <h1>Авторизация</h1>
                    <Input
                        name='username'
                        value={values.username}
                        placeholder='E-mail, логин, номер телефона'
                        onChange={handleChange}
                        onBlur={handleBlur}/>
                    <Input
                        name='password'
                        type='password'
                        value={values.password}
                        placeholder='Пароль'
                        onChange={handleChange}
                        onBlur={handleBlur}/>
                    <button type='submit'>Вход</button>
                </form>

            </main>


            <footer className={styles.footer}>
                <div>
                    <a href='https://nintegra.ru' target='_blank'>nintegra.ru</a>
                </div>
                <div>
                    <span>{version}</span>
                </div>
            </footer>
        </div>
    )
}
