import {BACKEND_URL_PREFIX} from "../assets/const";
import {createNotification} from "../components/Notifications/Notifications";
import {checkAndUpdateToken} from "./auth";
import {buildDefaultHeaders} from "./helper";
import {
    SET_PRODUCTS,
    SET_PRODUCTS_LOADING,
    SET_PRODUCTS_PAGE,
    SET_PRODUCT,
    SET_PRODUCT_LOADING,
    SET_PRODUCT_IMAGES,
    SET_PRODUCT_IMAGES_LOADING
} from "./types";

export const setPage = (pageNumber) => ({
    type: SET_PRODUCTS_PAGE,
    payload: {
        pageNumber: pageNumber
    }
})

export const getProducts = (company, search) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({type: SET_PRODUCTS_LOADING, payload: true});
        const {pageNumber} = getState().products
        const max_results = getState().auth.itemsOnPage
        const paginate_from = getState().auth.itemsOnPage * pageNumber
        let copyParams = {
            per_page: max_results,
            page: paginate_from,
            companyid: company,
            search: search
        }

        const urlParams = new URLSearchParams(copyParams).toString();

        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/items?${urlParams}`, {
                method: "GET",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {

                const {num: count, data} = await response.json();
                const pageCount = Math.ceil(count / getState().auth.itemsOnPage)
                dispatch({type: SET_PRODUCTS, payload: data});
                dispatch({
                    type: SET_PRODUCTS_PAGE,
                    payload: {
                        pageCount,
                        count
                    }
                });;
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            dispatch({type: SET_PRODUCTS_LOADING, payload: false});
        }
    }
};

export const getProduct = (id) => async(dispatch, getState) => {
    dispatch({type: SET_PRODUCT_LOADING, payload: true});

    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/item/${id}`, {
            method: "GET",
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            const data = await response.json();
            dispatch({type: SET_PRODUCT, payload: data});
        } else {
            createNotification('error', 'Ошибка')
        }
    }
    dispatch({type: SET_PRODUCT_LOADING, payload: false});
};

export const createProduct = (data) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({type: SET_PRODUCT_LOADING, payload: true});
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/item`, {
                method: "POST",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getProducts());
        }
    }
    dispatch({type: SET_PRODUCT_LOADING, payload: false});
};

export const updateProduct = (data) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({type: SET_PRODUCT_LOADING, payload: true});
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/item/${data.id}`, {
                method: "PUT",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getProducts());
        }
    }
    dispatch({type: SET_PRODUCT_LOADING, payload: false});
};

export const deleteProduct = (id, id_company) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({type: SET_PRODUCT_LOADING, payload: true});
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/item/${id}`, {
                method: "DELETE",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getProducts(id_company));
        }
    }
    dispatch({type: SET_PRODUCT_LOADING, payload: false});
};

// IMAGES

export const getImageList = (id) => async(dispatch, getState) => {
    dispatch({type: SET_PRODUCT_IMAGES_LOADING, payload: true});

    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/item/${id}/image`, {
            method: "GET",
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            // const res = await response.json();
            // const imageUrl = new Blob([res.data])
            // console.log(imageUrl)
            // dispatch({type: SET_PRODUCT_IMAGES, payload: imageUrl});
            dispatch({type: SET_PRODUCT_IMAGES, payload: true});
        } else {
            // createNotification('error', 'Ошибка')
            dispatch({type: SET_PRODUCT_IMAGES, payload: false});
        }
    }
    dispatch({type: SET_PRODUCT_IMAGES_LOADING, payload: false});
};

export const uploadImage = (id, image) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    const fd = new FormData();
    fd.append("file", image.file);

    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/item/${id}/image`, {
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
            },
            body: fd
        });
        if (response.ok) {
            dispatch(getProducts());
        } else {
            createNotification('error', 'Ошибка')
        }
    }
};

export const deleteImage = (id) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/item/${id}/image`, {
            method: "DELETE",
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            dispatch(getImageList(id));
            createNotification('success', 'Успешно')
        } else {
            createNotification('error', 'Ошибка')
        }
    }
};

// IMAGES

export const uploadProducts = (id, file) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    const fd = new FormData();
    fd.append("file", file);

    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/import/${id}/excel`, {
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
            },
            body: fd
        });
        if (response.ok) {
            dispatch(getProducts(id));
            createNotification('success', 'Успешно!')
        } else {
            createNotification('error', 'Ошибка')
        }
    }
};