import React from 'react'
import styles from './index.module.css'
import Input from '../Input'
import moment from 'moment'
const Index = ({params, setParams, body}) => {

    const handleChange = (e) => {
        const {name, value} = e.target
        let copy = {
            ...params
        }

        if (name == 'date_from') {
            copy[name] = moment(value).format('YYYY-MM-DD')
        } else if (name == 'date_to') {
            copy[name] = moment(value).format('YYYY-MM-DD')
        } else {
            copy[name] = value
        }
        setParams(copy)
    }

    return (
        <div className={styles.wrapper}>
            {body.map((row, index) => <div
                className={styles.row}
                style={{
                width: '100%'
            }}
                key={index}>

                {row.map(col => <div className={styles.col} key={col.name} style={col.style}>
                    <Input
                        onChange={handleChange}
                        name={col.name}
                        type={col.type}
                        placeholder={col.placeholder}
                        value={params[col.name]
                        ? params[col.name]
                        : ''}/>
                </div>)}
            </div>)}
        </div>
    )
}

export default Index