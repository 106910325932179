import {BACKEND_URL_PREFIX} from "../assets/const";
import {createNotification} from "../components/Notifications/Notifications";
import {checkAndUpdateToken} from "./auth";
import {buildDefaultHeaders} from "./helper";
import {
    SET_CATEGORIES,
    SET_CATEGORIES_LOADING,
    SET_CATEGORIES_PAGE,
    SET_CATEGORY,
    SET_CATEGORY_LOADING
} from "./types";

export const setPage = (pageNumber) => ({
    type: SET_CATEGORIES_PAGE,
    payload: {
        pageNumber: pageNumber
    }
})

export const getCategories = (params) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({type: SET_CATEGORIES_LOADING, payload: true});
        // const {pageNumber} = getState().categories
        // const max_results = getState().auth.itemsOnPage
        // const paginate_from = getState().auth.itemsOnPage * pageNumber
        let params_ = {}
        for (let key in params) {
            if (params[key] != '' && params[key] != null) params_[key] = params[key]
          }

        let copyParams = {
            // per_page: max_results,
            // page: paginate_from,
            ...params_
        }

        const urlParams = new URLSearchParams(copyParams).toString();

        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/categories?${urlParams}`, {
                method: "GET",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {

                const {num: count, data} = await response.json();
                const pageCount = Math.ceil(count / getState().auth.itemsOnPage)
                dispatch({type: SET_CATEGORIES, payload: data});
                dispatch({
                    type: SET_CATEGORIES_PAGE,
                    payload: {
                        pageCount,
                        count
                    }
                });
            } else {
                dispatch({type: SET_CATEGORY, payload: []});
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            dispatch({type: SET_CATEGORIES_LOADING, payload: false});
        }
    }
};


export const getCategory = (id) => async(dispatch, getState) => {
    dispatch({type: SET_CATEGORY_LOADING, payload: true});

    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/category/${id}`, {
            method: "GET",
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            const data = await response.json();
            dispatch({type: SET_CATEGORY, payload: data});
        } else {
            createNotification('error', 'Ошибка')
        }
    }
    dispatch({type: SET_CATEGORY_LOADING, payload: false});
};

export const createCategory = (data) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({type: SET_CATEGORY_LOADING, payload: true});
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/category`, {
                method: "POST",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getCategories());
        }
    }
    dispatch({type: SET_CATEGORY_LOADING, payload: false});
};

export const updateCategory = (data) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({type: SET_CATEGORY_LOADING, payload: true});
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/category/${data.id}`, {
                method: "PUT",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getCategories());
        }
    }
    dispatch({type: SET_CATEGORY_LOADING, payload: false});
};

export const deleteCategory = (id) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({type: SET_CATEGORY_LOADING, payload: true});
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/category/${id}`, {
                method: "DELETE",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getCategories());
        }
    }
    dispatch({type: SET_CATEGORY_LOADING, payload: false});
};