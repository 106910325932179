import ReactPaginate from "react-paginate"
import styles from "./styles.module.css"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Loader from "../Loader";
import { itemsCount } from "../../assets/const";
import { setItemsOnPage } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";

export const Pagination = ({
    pageCount,
    onPageChange = () => {},
    pageNumber = 0,
    children,
    loading   
}) => {

    const dispath = useDispatch()
    const {itemsOnPage} = useSelector(state => state.auth)
    
    const Paginate = () => (
        <div className={styles.paginateWrapper}>

            <div className={styles.count}>
              <select value={itemsOnPage} onChange={(e) => dispath(setItemsOnPage(e.target.value))}>
                {itemsCount.map(item => <option key={item} value={item}>{item}</option>)}
              </select>
              <span>Кол-во записей</span>
            </div>

                {pageCount > 0 &&
                <ReactPaginate
                    previousLabel={pageNumber > 0 && <IoIosArrowBack size={22}/>}
                    nextLabel={pageCount-pageNumber > 1 && <IoIosArrowForward size={22}/>}
                    breakLabel={"..."}
                    marginPagesDisplayed={2}
                    pageCount={pageCount || 1}
                    pageRangeDisplayed={5}
                    forcePage={pageNumber}
                    onPageChange={onPageChange}
                    containerClassName={styles.container}
                    conta
                    activeClassName={styles.active}
                    breakClassName={styles.break}
                />
                }
                {loading && <Loader/>}
            </div>
    )


    return (
        <>
            <Paginate/>
                {children}
            <Paginate/>
        </>
    )
}