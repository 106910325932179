import React, {useEffect, useState} from 'react'
import styles from './index.module.css'
import Modal from '../../../components/Modal'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import Confirmation from '../../../components/Confirmation'
import {useDispatch, useSelector} from 'react-redux'
import {getCompany, updateCompany, createCompany} from '../../../actions/companies'
import { createNotification } from '../../../components/Notifications/Notifications'

const Index = ({modal, setModal}) => {

    const dispatch = useDispatch()
    const {item, item_loading} = useSelector(state => state.companies)

    const [values,
        setValues] = useState(modal.id)
    const [confirmation,
        setConfirmation] = useState(null);

    const handleChange = (e, name_) => {
        const {name, value} = e.target
        let copy = {
            ...values
        }

        if (name_) {
            copy[name_] = {
                ...copy[name_],
                [name]: value
            }
        } else {
            copy[name] = value
        }
        setValues(copy)
    }

    useEffect(() => {
        if (modal
            ?.id) {
            dispatch(getCompany(modal.id))
        } else {
            setValues({name: '', id: ''})
        }
    }, [modal])

    useEffect(() => {
        if (item) {
            setValues(item)
        }
    }, [item])

    const handleSubmit = () => {
        if (values?.name?.length == 0) return createNotification('error', 'Название не может быть пустым')
        if (modal == 'ADD') {
            // dispatch(createCompany(values))
            handleClose()
        } else {
            editCompany()
            handleClose()
        }
    }

    const editCompany = async() => {
        if (values.name) {
            await dispatch(updateCompany(values))
        }
    };

    const handleClose = () => {
        setModal(false)
    }

    const acceptConfirmation = () => {
        setConfirmation(null)
    }

    return (
        <div className={styles.wrapper}>

            {confirmation && <Confirmation
                confirmation={confirmation.name}
                acceptConfirmation={acceptConfirmation}
                rejectConfirmation={() => setConfirmation(null)}/>}

            <Button
                mr='.5rem 0'
                text='Добавить'
                type='primary'
                onClick={() => setModal('ADD')}/>

            <Modal
                open={modal}
                width={1000}
                onClose={() => handleClose(false)}
                title='компании'
                loading={item_loading}>
                <div
                    className={styles.row}
                    style={{
                    width: '100%'
                }}>
                    <div className={styles.col}>
                        <Input
                            onChange={(e) => handleChange(e)}
                            name='name'
                            type="text"
                            placeholder="Название"
                            value={values
                            ?.name}/>
                    </div>
                </div>

                <div className={styles.btns}>
                    <Button text='Закрыть' onClick={() => setModal(false)} type='empty'/>
                    <Button text='Сохранить' onClick={handleSubmit}/>
                </div>
            </Modal>
        </div>
    )
}

export default Index