import React from 'react'
import styles from './index.module.css'
import Button from '../Button'

export default function Index({
    absolute = true,
    top = "",
    left = "",
    confirmation = "",
    // title = "Вы уверены что хотите удалить",
    color = '#000',
    acceptConfirmation = () => {},
    rejectConfirmation = () => {}    
}) {
  return (
    <div className={absolute ? styles.absolute : null}>
      <div className={styles.confirmation} style={absolute ? null : {position: "absolute", top : top, left : left}}>
      <div className={styles.textBlock} styles={{color: color}}>
        {/* {title} */}
        {confirmation ? 
           <span> <b>Удалить</b> {confirmation} ?</span>
        :
        ""  
        }
        
      </div>
      <div className={styles.buttons}>
        <Button text='Да'  onClick={() => acceptConfirmation(confirmation)}/>
        <Button text='Нет' onClick={() => rejectConfirmation()}/>
        </div> 
    </div>
    </div>
  )
}
