import React from "react";
import {BrowserRouter, Navigate, Route, Routes as Switch} from "react-router-dom";

import {PrivateRoute} from "../components/Routing";

import Layout from "../components/Layout";
import NotFound from "../pages/NotFound/NotFound";
import Auth from '../pages/Auth'
import Main from "../pages/Main/index";
import Products from "../pages/Products";
import Messages from "../pages/Messages";
import Company from "../pages/Company";
import Category from "../pages/Category";

export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path='/auth' element={<Auth />}/>
                <Route element={<PrivateRoute />}>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Main />}/>
                        <Route path="/products" element={<Products />}/>
                        <Route path="/messages" element={<Messages />}/>
                        <Route path="/company" element={<Company />}/>
                        <Route path="/category" element={<Category />}/>
                        <Route path="/notFound" element={<NotFound />}/>
                    </Route>
                </Route>
                <Route path="*" element={<Navigate to = '/notFound' replace />}/>
            </Switch>
        </BrowserRouter>
    );
}