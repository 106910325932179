import * as types from "../actions/types";

const initialState = {
    pageNumber: 0,
    pageCount: 0,
    count: 0,

    list: [],
    list_loading: false,
    item: {},
    item_loading: false,

    images: [],
    images_loading: false
};

export default function products(state = initialState, action) {
    switch (action.type) {
        case types.SET_PRODUCTS:
            return {
                ...state,
                list: action.payload
            };
        case types.SET_PRODUCTS_PAGE:
            return {
                ...state,
                ...action.payload
            };
        case types.SET_PRODUCTS_LOADING:
            return {
                ...state,
                list_loading: action.payload
            };
        case types.SET_PRODUCT:
            return {
                ...state,
                item: action.payload,
            };
        case types.SET_PRODUCT_LOADING:
            return {
                ...state,
                item_loading: action.payload
            };
        case types.SET_PRODUCT_IMAGES:
            return {
                ...state,
                images: action.payload,
            };
        case types.SET_PRODUCT_IMAGES_LOADING:
            return {
                ...state,
                images_loading: action.payload
            };
        default:
            return state;
    }
}