import React from 'react'
import styles from './styles.module.css'
import TitleAndDesc from '../../components/TitleAndDesc/TitleAndDesc'


export default function Main() {

    return (
        <div className={styles.wrapper}>
            <TitleAndDesc title="Главная"/>
        </div>
    )
}
