import React, {useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import styles from './styles.module.css'
import SideBar from './SideBar'
import Header from './Header'

export default function Layout() {

    const [isSideBar, setIsSideBar] = useState(false);

    const handleSidebar = () => setIsSideBar(!isSideBar) //показать/скрыть боковую панель

    useEffect(() => {
        window.innerWidth <= 768 && setIsSideBar(true)
    }, [])

    return (
        <div className={styles.wrapper}>

            <SideBar isSideBar={isSideBar} handleSidebar={handleSidebar}/>

            <div className={styles.content}>
                <Header/>

                <main className={styles.main}>
                    <Outlet/>
                </main>
            </div>

        </div>
    )
}
