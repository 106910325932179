import React, {useEffect, useState} from 'react'
import styles from './index.module.css'
import TitleAndDesc from '../../components/TitleAndDesc/TitleAndDesc'
import Modal from './Modal'
import Table from '../../components/Table'
import Icon from '../../components/Icon'
import Confirmation from '../../components/Confirmation'
import {useDispatch, useSelector} from 'react-redux'
import {BiSolidTrashAlt} from 'react-icons/bi'
import {getCategories, setPage, deleteCategory} from '../../actions/categories'
import {Pagination} from '../../components/Pagination'

const Index = () => {

    const dispatch = useDispatch()

    const [modal,
        setModal] = useState(false)
    const {list, list_loading, pageNumber, pageCount} = useSelector(state => state.categories)
    const [confirmation,
        setConfirmation] = useState(null);
    // const {itemsOnPage} = useSelector(state => state.auth) const [company,
    // setCompany] = useState(null)

    useEffect(() => {
        dispatch(getCategories())
    }, [pageNumber])

    useEffect(() => {
        dispatch(getCategories())
    }, [])

    // useEffect(() => {     pageClick({selected: 0})
    // dispatch(getCategories(company?.value || null))   }, [itemsOnPage, company])

    const pageClick = ({selected}) => dispatch(setPage(selected))

    const acceptConfirmation = () => {
        dispatch(deleteCategory(confirmation.id))
        setConfirmation(null)
    }

    return (
        <div className={styles.wrapper}>

            {confirmation && <Confirmation
                confirmation={confirmation.category_name}
                acceptConfirmation={acceptConfirmation}
                rejectConfirmation={() => setConfirmation(null)}/>}

            <TitleAndDesc title='Категории'/>

            <Modal
                modal={modal}
                setModal={setModal}
                categories={list}
                setConfirmation={setConfirmation}/>

            {/* <Pagination
                pageCount={pageCount}
                pageNumber={pageNumber}
                onPageChange={pageClick}> */}

                <Table isEmpty={list} isLoad={list_loading}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Название</th>
                            <th>Активный</th>
                            <th
                                style={{
                                width: '120px'
                            }}>Удалить</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(el => <tr key={el.id}>
                            <td onClick={() => setModal(el)}>{el.id}</td>
                            <td onClick={() => setModal(el)}>{el.category_name}</td>
                            <td onClick={() => setModal(el)}>{el.active ? 'Активный' : 'Неактивный'}</td>
                            <td
                                onClick={() => setConfirmation(el)}
                                key={el.id}
                                style={{
                                width: '120px'
                            }}
                                title='Удалить пользователя'>
                                <Icon color='red'><BiSolidTrashAlt/></Icon>
                            </td>
                        </tr>)}
                    </tbody>
                </Table>

            {/* </Pagination> */}
        </div>
    )
}

export default Index