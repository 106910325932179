import {BACKEND_URL_PREFIX} from "../assets/const";
import {createNotification} from "../components/Notifications/Notifications";
import {checkAndUpdateToken} from "./auth";
import {buildDefaultHeaders} from "./helper";
import {SET_COMPANIES, SET_COMPANIES_LOADING, SET_COMPANIES_PAGE, SET_COMPANY, SET_COMPANY_LOADING} from "./types";

export const setPage = (pageNumber) => ({
    type: SET_COMPANIES_PAGE,
    payload: {
        pageNumber: pageNumber
    }
})

export const getCompanies = () => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({type: SET_COMPANIES_LOADING, payload: true});
        const {pageNumber} = getState().companies
        const max_results = getState().auth.itemsOnPage
        const paginate_from = getState().auth.itemsOnPage * pageNumber
        let params_ = {}

        let copyParams = {
            per_page: max_results,
            page: paginate_from,
            ...params_
        }

        const urlParams = new URLSearchParams(copyParams).toString();

        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/company`, {
                method: "GET",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {

                const data = await response.json();
                // const pageCount = Math.ceil(count / getState().auth.itemsOnPage)
                dispatch({type: SET_COMPANIES, payload: data});
            } else {
                dispatch({type: SET_COMPANIES_PAGE, payload: []});
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            dispatch({type: SET_COMPANIES_LOADING, payload: false});
        }
    }
};

export const getCompany = (id) => async(dispatch, getState) => {
    dispatch({type: SET_COMPANY_LOADING, payload: true});

    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/company/${id}`, {
            method: "GET",
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            const data = await response.json();
            dispatch({type: SET_COMPANY, payload: data});
        } else {
            createNotification('error', 'Ошибка')
        }
    }
    dispatch({type: SET_COMPANY_LOADING, payload: false});
};

export const createCompany = (data) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({type: SET_COMPANY_LOADING, payload: true});
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/company`, {
                method: "POST",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getCompanies());
        }
    }
    dispatch({type: SET_COMPANY_LOADING, payload: false});
};

export const updateCompany = (data) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({type: SET_COMPANY_LOADING, payload: true});
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/company/${data.id}`, {
                method: "PUT",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getCompanies());
        }
    }
    dispatch({type: SET_COMPANY_LOADING, payload: false});
};

export const deleteCompany = (id) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({type: SET_COMPANY_LOADING, payload: true});
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/company/${id}`, {
                method: "DELETE",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getCompanies());
        }
    }
    dispatch({type: SET_COMPANY_LOADING, payload: false});
};