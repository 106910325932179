import React from 'react'
import styles from './index.module.css'
import {Link, useLocation} from 'react-router-dom'
import {mainMenu, version} from '../../../assets/const'
// import Icon from '../../../assets/img/layout/mark.svg'
import { BiSolidBot } from "react-icons/bi";

const Index = ({isSideBar, handleSidebar}) => {

    const params = useLocation();

    const getActive = (link) => { //получаем активную ссылку
        return params.pathname === link
            ? styles.active
            : ''
    }

    return (
        <div
            className={styles.sidebar + ' ' + (isSideBar
            ? styles.sideBar__active
            : '')}>
            <i className={styles.emblem} onClick={handleSidebar}>
            <BiSolidBot/>
            {/* <img src={Icon}/> */}
            </i>

            {mainMenu.map(link => <Link
                key={link.name}
                to={link.link}
                title={link.name}
                className={styles.link + ' ' + (getActive(link.link)
                ? styles.active
                : '')}>
                <i className={styles.icon}>{link.icon}</i>
                {!isSideBar && link.name}</Link>)}

            <div
                className={styles.version + ' ' + (isSideBar
                ? styles.version__active
                : '')}>{version}</div>
        </div>
    )
}

export default Index