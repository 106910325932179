import {BACKEND_URL_PREFIX} from "../assets/const";
import {createNotification} from "../components/Notifications/Notifications";
import {checkAndUpdateToken} from "./auth";
import {buildDefaultHeaders} from "./helper";
import {
    SET_MESSAGES,
    SET_MESSAGES_LOADING,
    SET_MESSAGES_PAGE,
    SET_MESSAGE,
    SET_MESSAGE_LOADING
} from "./types";

export const setPage = (pageNumber) => ({
    type: SET_MESSAGES_PAGE,
    payload: {
        pageNumber: pageNumber
    }
})

export const getMessages = (params) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({type: SET_MESSAGES_LOADING, payload: true});
        const {pageNumber} = getState().messages
        const max_results = getState().auth.itemsOnPage
        const paginate_from = getState().auth.itemsOnPage * pageNumber
        let params_ = {}
        for (let key in params) {
            if (params[key] != '' && params[key] != null) params_[key] = params[key]
          }

        let copyParams = {
            per_page: max_results,
            page: paginate_from,
            ...params_
        }

        const urlParams = new URLSearchParams(copyParams).toString();

        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/messages?${urlParams}`, {
                method: "GET",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {

                const {num: count, data} = await response.json();
                const pageCount = Math.ceil(count / getState().auth.itemsOnPage)
                dispatch({type: SET_MESSAGES, payload: data});
                dispatch({
                    type: SET_MESSAGES_PAGE,
                    payload: {
                        pageCount,
                        count
                    }
                });
            } else {
                dispatch({type: SET_MESSAGES, payload: []});
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            dispatch({type: SET_MESSAGES_LOADING, payload: false});
        }
    }
};

export const getMessage = (id) => async(dispatch, getState) => {
    dispatch({type: SET_MESSAGE_LOADING, payload: true});

    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/message/${id}`, {
            method: "GET",
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            const data = await response.json();
            dispatch({type: SET_MESSAGE, payload: data});
        } else {
            createNotification('error', 'Ошибка')
        }
    }
    dispatch({type: SET_MESSAGE_LOADING, payload: false});
};

