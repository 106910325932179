import React from "react";
import styles from "./index.module.css"

const Index = ({
    label = '',
    value,
    onChange = () => {}
}) => {

    return (
        <div className={styles.wrapper}>
            <p className={styles.label}>{label}</p>
            <label className={styles.switch}>
                <input type="checkbox" checked={value}/>
                <span className={styles.slider} onClick={() => onChange(!value)}/>
            </label>
        </div>
    );
};

export default Index;
