import React from 'react'
import styles from './index.module.css'
import Modal from 'react-responsive-modal'
import Loader from '../Loader'

const Index = ({children, open, onClose, title, loading, width, singleTitle = ''}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            showCloseIcon={true}
            closeOnEsc={true}
            closeOnOverlayClick={true}
            animationDuration={500}
            top
            styles={{
                modal: {
                    margin: '2.5rem auto',
                    borderRadius: '.25rem',
                    width: '95%',
                    maxWidth: `${width}px`,
            }}  }
            classNames={{
            modal: styles.modal
        }}>
            <div className={styles.title}>
                {singleTitle ? singleTitle : ''}
                {!singleTitle && `${open != 'ADD' ? 'Редактирование' : 'Создание'} ${title}`}</div>
            {loading
                ? <Loader/>
                : children}
        </Modal>
    )
}

export default Index