import React from 'react'
import styles from './index.module.css'
const Index = ({color = 'black', ...props}) => {
  return (
    <i 
        className={styles.icon} 
        style={{color: color}}>
            {props.children}
    </i>
  )
}

export default Index