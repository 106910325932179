import * as types from "../actions/types";

const initialState = {
    pageNumber: 0,
    pageCount: 0,
    count: 0,

    list: [],
    list_loading: false,
    item: [],
    item_loading: false,
};

export default function companies(state = initialState, action) {
    switch (action.type) {
        case types.SET_COMPANIES:
            return {
                ...state,
                list: action.payload
            };
        case types.SET_COMPANIES_PAGE:
            return {
                ...state,
                ...action.payload
            };
        case types.SET_COMPANIES_LOADING:
            return {
                ...state,
                list_loading: action.payload
            };
        case types.SET_COMPANY:
            return {
                ...state,
                item: action.payload,
            };
        case types.SET_COMPANY_LOADING:
            return {
                ...state,
                item_loading: action.payload
            };
        default:
            return state;
    }
}