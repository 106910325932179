import React from 'react'
import styles from './index.module.css'

const Index = ({
    propsstyle = {},
    ...props
}) => {

    return (
        <div className={styles.wrapper} style={{...propsstyle}}>
            <span className={styles.placeholder}>{props.placeholder}</span>
            <input className={styles.input} {...props}/>
        </div>
    )
}

export default Index