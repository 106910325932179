import React, {useEffect, useState} from 'react'
import styles from './index.module.css'
import Modal from '../../../components/Modal'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import CheckBox from '../../../components/CheckBox'
import Confirmation from '../../../components/Confirmation'
import {useDispatch, useSelector} from 'react-redux'
import {getCategory, updateCategory, createCategory} from '../../../actions/categories'
import { createNotification } from '../../../components/Notifications/Notifications'

const Index = ({modal, setModal}) => {

    const dispatch = useDispatch()
    const {item, item_loading} = useSelector(state => state.categories)

    const [values,
        setValues] = useState(modal.id)
    const [confirmation,
        setConfirmation] = useState(null);

    const handleChange = (e, name_) => {
        const {name, value} = e.target
        let copy = {
            ...values
        }

        if (name_) {
            copy[name_] = {
                ...copy[name_],
                [name]: value
            }
        } else {
            copy[name] = value
        }
        setValues(copy)
    }

    useEffect(() => {
        if (modal
            ?.id) {
            dispatch(getCategory(modal.id))
        } else {
            setValues({category_name: '', id: '', ext_category_ids: null, active: false})
        }
    }, [modal])

    useEffect(() => {
        if (item) {
            setValues(item)
        }
    }, [item])

    const handleSubmit = () => {
        if (values?.category_name?.length == 0) return createNotification('error', 'Название не может быть пустым')
        if (modal == 'ADD') {
            dispatch(createCategory(values))
            handleClose()
        } else {
            editCategory()
            handleClose()
        }
    }

    const editCategory = async() => {
        if (values.category_name) {
            await dispatch(updateCategory(values))
        }
    };

    const handleClose = () => {
        setModal(false)
    }

    const acceptConfirmation = () => {
        setConfirmation(null)
    }

    return (
        <div className={styles.wrapper}>

            {confirmation && <Confirmation
                confirmation={confirmation.category_name}
                acceptConfirmation={acceptConfirmation}
                rejectConfirmation={() => setConfirmation(null)}/>}

            <Button
                mr='.5rem 0'
                text='Добавить'
                type='primary'
                onClick={() => setModal('ADD')}/>

            <Modal
                open={modal}
                width={1000}
                onClose={() => handleClose(false)}
                title='компании'
                loading={item_loading}>
                <div
                    className={styles.row}
                    style={{
                    width: '100%'
                }}>
                    <div className={styles.col}>
                        <Input
                            onChange={(e) => handleChange(e)}
                            name='category_name'
                            type="text"
                            placeholder="Название"
                            value={values
                            ?.category_name}/>
                    </div>
                
                    <CheckBox 
                        onChange={(e) => handleChange({ target: { value: e, name: 'active' } })} 
                        value={values?.active}
                        label='Активно'
                        />

                </div>

                <div className={styles.btns}>
                    <Button text='Закрыть' onClick={() => setModal(false)} type='empty'/>
                    <Button text='Сохранить' onClick={handleSubmit}/>
                </div>
            </Modal>
        </div>
    )
}

export default Index