import storage from 'redux-persist/es/storage';
import {persistCombineReducers} from 'redux-persist';

import auth from './auth';
import products from './products';
import messages from './messages';
import companies from './companies';
import categories from './categories';

const storageConfig = {
    whitelist: 'auth',
    key: 'certification_storage',
    storage
};

export default persistCombineReducers(storageConfig, {auth, products, messages, companies, categories});