import React, {useEffect, useState} from 'react'
import styles from './styles.module.css'
import TitleAndDesc from '../../components/TitleAndDesc/TitleAndDesc'
import {getMessages, setPage} from '../../actions/messages'
import {useDispatch, useSelector} from 'react-redux'
import {Pagination} from '../../components/Pagination'
import Modal from './Modal'
import Table from '../../components/Table'
import Search from '../../components/Search'
import moment from 'moment'
import { getCompanies } from '../../actions/companies'

const body = [[
    {placeholder: 'Дата с', name: 'date_from', type: 'date', style: {maxWidth: '200px'}},
    {placeholder: 'Дата по', name: 'date_to', type: 'date', style: {maxWidth: '200px'}},
    {placeholder: 'Строка', name: 'searchstring', type: 'text', style: {maxWidth: '100%'}},
    ]
]

export default function Chats() {

    const dispatch = useDispatch()
    const {list, list_loading, pageNumber, pageCount} = useSelector(state => state.messages)
    const {itemsOnPage} = useSelector(state => state.auth)
    const [modal,
        setModal] = useState(false)
    const [params,
        setParams] = useState({
            searchstring: null,
            date_from: moment().format('YYYY-MM-DD'),
            date_to: moment().format('YYYY-MM-DD'),
        })

    useEffect(() => {
        pageClick({selected: 0})
    }, [])

    useEffect(() => {
        dispatch(getMessages({...params, date_to: params.date_to + ' 23:59:59'}))
    }, [itemsOnPage, params, pageNumber])

    const pageClick = ({selected}) => dispatch(setPage(selected))

    useEffect(() => {
        dispatch(getCompanies())
    }, [])

    return (
        <div className={styles.wrapper}>
            <TitleAndDesc title="Диалоги"/>

            <Modal modal={modal} setModal={setModal}/>

            <Search params={params} setParams={setParams} body={body}/>

            <Pagination
                pageCount={pageCount}
                pageNumber={pageNumber}
                onPageChange={pageClick}>

                <Table isEmpty={list} isLoad={list_loading}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Дата</th>
                            <th>Имя</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(el => <tr key={el.conversation_id}>
                            <td onClick={() => setModal(el)} style={{width: '60px'}}>{el.conversation_id}</td>
                            <td onClick={() => setModal(el)} style={{width: '180px'}}>{moment(el.created).format('DD.MM.YYYY HH:mm')}</td>
                            <td onClick={() => setModal(el)}>{el.name}</td>
                            <td onClick={() => setModal(el)}>{el.email}</td>
                        </tr>)}
                    </tbody>
                </Table>

            </Pagination>
        </div>
    )
}
